<script setup lang="ts">
import { darkModeKey, styleKey } from "@/configs/config";
import { useStyleStore } from "@/stores/style";
const router = useRouter();
const styleStore = useStyleStore();
router.beforeEach(() => {
  const currentStyle =
    typeof localStorage !== "undefined" && localStorage[styleKey]
      ? localStorage[styleKey]
      : "basic";
  styleStore.setStyle(currentStyle);

  // set default theme to light mode
  styleStore.setDarkMode(false);
  // const currentStoredDarkMode =
  //   typeof localStorage !== "undefined" && localStorage[darkModeKey] === "1";

  // if (
  //   (!currentStoredDarkMode &&
  //     typeof window !== "undefined" &&
  //     window.matchMedia("(prefers-color-scheme: dark)").matches) ||
  //   currentStoredDarkMode
  // ) {
  //   styleStore.setDarkMode(true);
  // }
});
</script>

<template>
  <div :class="{ dark: styleStore.darkMode }">
    <div class="bg-gray-50 dark:bg-slate-800 dark:text-slate-100">
      <slot />
    </div>
  </div>
</template>
